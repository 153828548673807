<template>
  <div class="main">
    <p>基于GAP的农产品生产过程及农产品质量安全控制信息系统标准设计模式</p>
    <div><iframe class="pdf" 
    :src="'https://view.officeapps.live.com/op/view.aspx?src=http://nfsmid7.znglzx.cn/filedown/csff/基于GAP的农产品生产过程及农产品质量安全控制信息系统标准设计模式.doc'" 
    frameborder="0"></iframe>
    </div>
    <a :href="pdfSrc" target="_blank">基于GAP的农产品生产过程及农产品质量安全控制信息系统标准设计模式</a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        pdfSrc: "http://nfsmid7.znglzx.cn/filedown/csff/基于GAP的农产品生产过程及农产品质量安全控制信息系统标准设计模式.pdf"
      }
    }
  }
</script>

<style scoped>
.main{
  padding: 50px;
}
.pdf{
  width: 1400px;
  height: 1000px;
}
p{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
a{
  text-decoration: none;
  color: black;
  margin: 20px;
  margin-top: 50px;
  font-size: 18px;
}
a:hover{
  color: green;
}
</style>